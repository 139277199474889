import { useEffect, useState } from 'react';
import './App.css';
import Template from './Template';
import { Margin, usePDF } from "react-to-pdf";
import axios from 'axios';
import PrivacyPolicy from './PrivacyPolicy';
function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const tokenNumber = queryParameters.get("tokenNumber")
  const storeId = queryParameters.get("storeId")
  const [receiptDetail, setReceiptDetails] = useState(null)
  const [privacyPolicy, showPrivacyPolicy] = useState(false)
  // const { toPDF, targetRef } = usePDF({
  //   method: "save",
  //   filename: "usepdf-example.pdf",
  //   page: { margin: Margin.LARGE },
  // });
  useEffect(() => {
    if (tokenNumber && storeId) {
      getReceiptDetails();
      showPrivacyPolicy(false)
    }
    else {
      showPrivacyPolicy(true)
    }
  }, [])
  const getReceiptDetails = async () => {
    try {
      const data = await axios.post("http://www.ccdynamicss.com:8000/api/v1/receipt/getStoreReceipt", {
        tokenNumber: tokenNumber,
        storeId: storeId
      })
      setReceiptDetails(data?.data?.data)
    }
    catch (error) {
      setReceiptDetails(null)
    }
  }
  return (
    <div className="App">
      {privacyPolicy ?
        <div>
          <PrivacyPolicy />
        </div> :
        <div >
          {receiptDetail &&
            <Template storeDetails={receiptDetail?.storeDetails} retailerName={receiptDetail?.retailerName} retailerAddress={receiptDetail?.retailerName} receiptNumber={receiptDetail?.receiptNumber} date={receiptDetail?.date} amount={receiptDetail?.amount} discount={receiptDetail?.discount} total={receiptDetail?.amount} description={receiptDetail?.description} creatorName={receiptDetail?.creatorName} />
          }
        </div>
      }
    </div>
  );
}

export default App;
