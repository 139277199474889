import React from 'react';

const PrivacyPolicy = () => {
  const policyStyle = {
    color: 'black'
  };

  return (
    <div style={policyStyle}>
      <h1>Privacy Policy for EZinvoice</h1>
      <h2>Introduction</h2>
      <p>
        EZinvoice ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our e-invoicing app ("App"). Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
      </p>

      <h2>Information We Collect</h2>
      <p>We may collect and process the following types of personal and sensitive user data:</p>
      <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, phone number.</li>
        <li><strong>Financial Information:</strong> Billing and payment information, transaction history.</li>
        <li><strong>Device Information:</strong> Device type, operating system, unique device identifiers, IP address.</li>
        <li><strong>Usage Data:</strong> Information about how you use our App, such as access times, pages viewed, and interaction data.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use the collected data for the following purposes:</p>
      <ul>
        <li><strong>To Provide and Maintain Our Service:</strong> Ensuring the App functions as intended, including troubleshooting, data analysis, testing, research, and statistical purposes.</li>
        <li><strong>To Manage Your Account:</strong> To manage your registration as a user of the App.</li>
        <li><strong>To Process Transactions:</strong> Handling billing, payments, and invoicing.</li>
        <li><strong>To Communicate With You:</strong> Sending notices, updates, and support messages related to the App.</li>
        <li><strong>To Improve Our Services:</strong> Understanding and analyzing how you use the App to enhance its features and performance.</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>We do not share your personal information with third parties except in the following circumstances:</p>
      <ul>
        <li><strong>With Your Consent:</strong> We will share your information with companies, organizations, or individuals outside of EZinvoice when we have your consent to do so.</li>
        <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        <li><strong>With Service Providers:</strong> We may share your information with trusted service providers to help us operate, provide, and improve our services. These providers are bound by strict confidentiality obligations.</li>
      </ul>

      <h2>Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.</p>

      <h2>Data Retention</h2>
      <p>We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

      <h2>Your Data Protection Rights</h2>
      <p>Depending on your location, you may have the following rights regarding your personal data:</p>
      <ul>
        <li><strong>Access:</strong> The right to request copies of your personal data.</li>
        <li><strong>Rectification:</strong> The right to request that we correct any information you believe is inaccurate.</li>
        <li><strong>Erasure:</strong> The right to request that we erase your personal data, under certain conditions.</li>
        <li><strong>Restriction of Processing:</strong> The right to request that we restrict the processing of your personal data, under certain conditions.</li>
        <li><strong>Objection to Processing:</strong> The right to object to our processing of your personal data, under certain conditions.</li>
        <li><strong>Data Portability:</strong> The right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</li>
      </ul>

      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <p>
        <strong>Privacy Point of Contact:</strong><br />
        Email: <a href="mailto:privacy@ezinvoice.com">privacy@ezinvoice.com</a>
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>

      <h2>Compliance Checklist</h2>
      <ul>
        <li>This privacy policy is linked on our app’s store listing page in Play Console and within the app itself.</li>
        <li>It refers to EZinvoice, the entity named in the app’s Google Play listing.</li>
        <li>It is labeled clearly as a privacy policy.</li>
        <li>A privacy point of contact is provided.</li>
        <li>It is readable in a standard browser without any plug-ins or special handlers.</li>
        <li>It is available on an active, publicly accessible and non-geofenced URL.</li>
        <li>It is non-editable.</li>
        <li>It comprehensively discloses how our app accesses, collects, uses, and shares user data, including secure data handling procedures and our data retention and deletion policy.</li>
      </ul>
      
      <p>By using our App, you acknowledge that you have read and understood this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;