// import React from "react";
// import "./template.css";
// // import logo "./logo.svg";

// const Template = ({
//   title = "Welcome to Our Sample Component",
// }) => {
//   return (
//     <div className="card-container">
//       <img
//         src="https://hips.hearstapps.com/hmg-prod/images/online-buying-and-delivery-concept-royalty-free-image-1675370119.jpg?crop=0.74996xw:1xh;center,top&resize=1200:*"
//         alt="Sample"
//         className="card-image"
//       />
//       <h2 className="card-title">{title}</h2>
//       <p className="card-paragraph">
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget
//         libero quam. Fusce efficitur, lectus ac commodo maximus, neque augue
//         tincidunt tellus, id dictum odio eros ac nulla.
//       </p>
//       <p className="card-paragraph">
//         Vivamus at urna sit amet justo auctor vestibulum ut nec nisl. Sed auctor
//         augue eget libero tincidunt, ut dictum libero facilisis. Phasellus non
//         libero at nisi eleifend tincidunt a eget ligula.
//       </p>
//     </div>
//   );
// };

// export default Template;


import React from 'react';
import "./template.css"
const Template = ({ storeDetails, retailerName, retailerAddress, receiptNumber, date, amount, discount, total, description, creatorName }) => {
  const numberToWords = (number) => {
    const singleDigits = [
      '',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
    ];
    const teens = [
      '',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ];
    const tens = [
      '',
      'Ten',
      'Twenty',
      'Thirty',
      'Forty',
      'Fifty',
      'Sixty',
      'Seventy',
      'Eighty',
      'Ninety',
    ];

    if (number === 0) return 'zero';
    if (number < 0) return 'minus ' + numberToWords(-number);

    let words = '';

    if (number >= 1000000000) {
      words += numberToWords(Math.floor(number / 1000000000)) + ' billion ';
      number %= 1000000000;
    }

    if (number >= 1000000) {
      words += numberToWords(Math.floor(number / 1000000)) + ' million ';
      number %= 1000000;
    }

    if (number >= 1000) {
      words += numberToWords(Math.floor(number / 1000)) + ' thousand ';
      number %= 1000;
    }

    if (number >= 100) {
      words += numberToWords(Math.floor(number / 100)) + ' hundred ';
      number %= 100;
    }

    if (number > 0) {
      if (words !== '') words += 'and ';

      if (number < 10) words += singleDigits[number];
      else if (number < 20) words += teens[number - 10];
      else {
        words += tens[Math.floor(number / 10)];
        if (number % 10 !== 0) words += '-' + singleDigits[number % 10];
      }
    }

    return words.trim();
  };
  return (
    <div className="container">
      <div className="store-info">
        <img src={`data:image/png;base64, ${storeDetails?.storeLogo}`} alt="Store Logo" />
        <div>
          <h2>{storeDetails?.name}</h2>
          <p>Address: {storeDetails?.address}</p>
          <p>Email: {storeDetails?.email}</p>
          <p>Phone Number: {storeDetails?.storeNumber}</p>
        </div>
      </div>

      <div className="receipt-details">
        <div>
          <p>Cash Receipt For: M/s. {retailerName}</p>
        </div>
        <div>
          <p>Receipt No.: #{receiptNumber}</p>
          <p>Date: {date}</p>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Amount</th>
            <th style={{ width: '50%' }}>Amount in Words</th>
            <th style={{ width: '25%' }}>Discount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>₹ {amount}</td>
            <td>{numberToWords(amount) + '  Rupees Only'}</td>
            <td>₹ {discount}</td>
          </tr>
          <tr>
            <td colSpan="2" className="total">Total</td>
            <td className="total">₹ {total}</td>
          </tr>
          {description &&
            <tr>
              <td colSpan="3"><strong>Description:</strong> {description}</td>
            </tr>
          }
        </tbody>
      </table>
      <div className="tax-details">
        <p>PAN: {storeDetails?.panNumber}, TAN: {storeDetails?.tanNumber}, CIN: {storeDetails?.cinNumber}</p>
        <p>GST No.: {storeDetails?.gstNumber}</p>
        <p>DL No.: {storeDetails?.dlNumber}</p>
      </div>

      <div className="signature">
        <div>
          <p>{creatorName}</p>
          <p>Authorized Representative</p>
        </div>
      </div>
    </div>
  );
};

export default Template;